import axios from "axios";

export function exchangeToken(request, sessionId) {
    return axios.post(process.env.REACT_APP_CRYPTO_PAYMENT_API + "/v1/token", request, {
        headers: {
            Authorization: `Bearer ${sessionId}`
        }
    })
}

export function getWallets() {
    const request = {
        command: "get-wallets"
    }

    return axios.post(process.env.REACT_APP_CRYPTO_PAYMENT_API + "/v1/wallet", request)
}

export function getAmount(selectedWalletCode) {
    const request = {
        command: "convert-amount",
        selected_wallet: selectedWalletCode
    }

    return axios.post(process.env.REACT_APP_CRYPTO_PAYMENT_API + "/v1/convert", request)
}

export function authorize(selectedWalletCode, fiatAmount, cryptoAmount, fiatCurrency) {
    const request = {
        command: "authorize-payment",
        fiat_amount: fiatAmount,
        crypto_amount: cryptoAmount,
        wallet_code: selectedWalletCode,
        fiat_currency: fiatCurrency
    }

    return axios.post(process.env.REACT_APP_CRYPTO_PAYMENT_API + "/v1/authorise-payment", request)
}