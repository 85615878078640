import SelectSearch from "react-select-search";
import "../css/search-select.css";
import {useContext, useEffect, useState} from "react";
import {authorize, getAmount, getWallets} from "../api/paymentAPIs";
import useLoadWallets from "../hooks/useLoadWallets";
import Spinner from "./Spinner";
import Error from "./Error";
import {appContext} from "../context/appContext";
import {useNavigate} from "react-router-dom";
import {toast} from "react-hot-toast";


export default function PaymentAuthorize({brandImage, termsURL, legalName}) {

    const navigate = useNavigate();


    const [isLoadingAmount, setIsLoadingAmount] = useState(false);
    const [isAuthorizing, setIsAuthorizing] = useState(false);
    const {wallets, isLoadingWallets, isErrorLoadingWallets} = useLoadWallets();
    const {amounts, setAmounts, setTransactionReference, selectedWalletCode, setSelectedWalletCode} = useContext(appContext);

    function onChangeWalletSelection(walletCode) {
        setSelectedWalletCode(walletCode);
        if (!amounts[walletCode] || amounts[walletCode] === 'ERROR') {
            setIsLoadingAmount(true);
            getAmount(walletCode)
                .then(res => setAmounts({...amounts, [walletCode]: res.data}))
                .catch(err => setAmounts({...amounts, [walletCode]: 'ERROR'}))
                .finally(() => setIsLoadingAmount(false))
        }
    }

    function authorizePayment() {
        setIsAuthorizing(true);
        authorize(selectedWalletCode, amounts[selectedWalletCode].amount_in_fiat, amounts[selectedWalletCode].amount_in_crypto, amounts[selectedWalletCode].currency_code)
            .then(res => {
                setTransactionReference(res.data.transaction_id);
                navigate("success");
            })
            .catch(err => toast.error("Failed to authorize!", {
                position: "bottom-center",
                style: {
                    background: "red",
                    color: "#fff"
                }
            }))
            .finally(() => setIsAuthorizing(false))
    }

    return (
        <>
            <div className="d-flex justify-content-center">
                <img src={`${process.env.REACT_APP_CRYPTO_PAYMENT_UI}/merchants/${brandImage}`} alt="test" style={{width: "150px"}}/>
            </div>
            {
                isLoadingWallets ? <Spinner /> : isErrorLoadingWallets ? <Error /> : (
                    <>
                        <div className="d-flex justify-content-center mt-5">
                            <SelectSearch options={wallets} id="test"
                                          onChange={onChangeWalletSelection}
                                          placeholder="Choose your wallet"

                            />
                        </div>
                        {
                            amounts[selectedWalletCode] && amounts[selectedWalletCode] !== 'ERROR' ? (
                                <>
                                    <div className="d-flex justify-content-center mt-5 mx-3">
                                        <h5 style={{color: "#0b456e", textAlign: "center"}}>
                                            Pay ${amounts[selectedWalletCode].amount_in_fiat.toFixed(2)} ({amounts[selectedWalletCode].amount_in_crypto} {selectedWalletCode}) to {legalName}
                                        </h5>
                                    </div>
                                    <div
                                        className={`d-flex justify-content-center ${isLoadingAmount ? 'mt-0' : amounts[selectedWalletCode] && amounts[selectedWalletCode] !== 'ERROR' || isLoadingAmount ? 'mt-3' : 'mt-5'}`}>
                                        <button className="btn btn-lg" style={{backgroundColor: "#0b456e", color: "white"}}
                                                onClick={authorizePayment}
                                                disabled={!amounts[selectedWalletCode] || amounts[selectedWalletCode] === 'ERROR' || isAuthorizing}>
                                            {isAuthorizing ? 'Authorizing' : 'Authorize' }
                                        </button>
                                    </div>
                                </>
                            ) : isLoadingAmount ? (
                                <Spinner />
                            ) : null
                        }
                    </>
                )
            }
            <div className={`d-flex justify-content-center ${selectedWalletCode ? isLoadingAmount ? 'mt-0' : 'mt-3' : 'mt-5'}`}>
                <a href={termsURL} target="_blank" style={{fontSize: "0.8rem", textDecoration: "none"}}>Terms & conditions</a>
            </div>
        </>
    );
}