import {getWallets} from "../api/paymentAPIs";
import {useEffect, useState} from "react";

export default function useLoadWallets() {

    const [wallets, setWallets] = useState([]);
    const [isLoadingWallets, setIsLoadingWallets] = useState(false);
    const [isError, setIsError] = useState(false);

    useEffect(() => {
        setIsLoadingWallets(true);
        getWallets()
            .then(res => {
                const data = res.data.wallets.map(wallet =>  ({name: wallet.description, value: wallet.wallet_code}));
                setWallets(data)
            })
            .catch(err => setIsError(true))
            .finally(() => setIsLoadingWallets(false))
    }, [])

    return {
        wallets,
        isLoadingWallets,
        isErrorLoadingWallets: isError
    }
}