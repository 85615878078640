import {useEffect, useState} from "react";
import {exchangeToken} from "../api/paymentAPIs";
import {configureRequestInterceptor} from "../interceptors/configureInterceptors";

export default function useExchangeToken(authorizationCode, sessionId) {
    const [isLoading, setIsLoading] = useState(true);
    const [isError, setIsError] = useState(false);
    const [response, setResponse] = useState({});

    useEffect(() => {
        if (authorizationCode) {
            const request = {
                command: "exchange-token",
                authorization_code: authorizationCode
            }
            exchangeToken(request, sessionId)
                .then(res => {
                    configureRequestInterceptor(res.data.session_id);
                    setResponse(res.data);
                })
                .catch(err => setIsError(true))
                .finally(() => setIsLoading(false))
        }
    }, [authorizationCode])

    return {
        isLoading,
        isError,
        response
    }
}