import {createContext, useState} from "react";

export const appContext = createContext();

export function AppContextProvider({children}) {
    const [amounts, setAmounts] = useState({});
    const [selectedWalletCode, setSelectedWalletCode] = useState('');
    const [transactionReference, setTransactionReference] = useState('');

    return (
        <appContext.Provider
            value={{
                transactionReference,
                setTransactionReference,
                amounts,
                setAmounts,
                setSelectedWalletCode,
                selectedWalletCode
            }}
        >
            {children}
        </appContext.Provider>
    )
}