import './App.css';
import useExchangeToken from "./hooks/useExchangeToken";
import Error from "./components/Error";
import Spinner from "./components/Spinner";
import UnAuthorized from "./components/UnAuthorized";
import {configureRequestInterceptor} from "./interceptors/configureInterceptors";
import {Route, Routes} from "react-router-dom";
import PaymentAuthorize from "./components/PaymentAuthorize";
import PaymentSuccess from "./components/PaymentSuccess";

function App() {

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const authorizationCode = urlParams.get('authorisation_code');
    const sessionId = urlParams.get('state');

    // HARDCODED SESSION ID FOR LOCAL DEVELOPMENT
    // const sessionId = "eyJhbGciOiJIUzI1NiJ9.eyJwYXltZW50X3RyYW5zYWN0aW9uX2lkIjoiY2QzYTc4M2QtNmYxNy00NjI2LTliODMtZTdlMDZmY2QyNWRkIiwiZXhwIjoiMTY1MTA5OTkzOSJ9.9m1u6o-2LwRmZvvaiuZRbHdPK_eAejdf52qvpMaLEIc";
    // configureRequestInterceptor(sessionId);

    const {isLoading, isError, response} = useExchangeToken(authorizationCode, sessionId);

    if (!authorizationCode || !sessionId) {
      return <UnAuthorized />
    }else if (isLoading) {
      return <Spinner />
    } else if (isError) {
      return <Error />
    }

    return (
        <Routes>
            <Route path="/" element={<PaymentAuthorize brandImage={response.brand_image || "payxcrypto.png"}
                                                       termsURL={response.terms_url}
                                                       legalName={response.legal_name}/>}/>
            <Route path="/success" element={<PaymentSuccess legalName={response.legal_name}/>}/>
        </Routes>
    );
}

export default App;
