import React from "react";
import '../css/spinner.css';

export default function Spinner() {
    return(
        <div className="loader" id="main-content-loader" style={{textAlign: "center"}}>
            <div className="lds-grid">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    );
}