import correct from "../icons/correct.svg"
import {useContext, useEffect} from "react";
import {appContext} from "../context/appContext";
import UnAuthorized from "./UnAuthorized";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faCoffee, faSpinner, faTruckLoading} from "@fortawesome/free-solid-svg-icons";

export default function PaymentSuccess({legalName}) {

    const {transactionReference, amounts, selectedWalletCode} = useContext(appContext);

    useEffect(() => {
        setTimeout(() => {
            backToCheckout();
        }, 5000);
    }, [])

    function backToCheckout() {
        window.top.postMessage('navatti-authorize-payment', '*');
    }

    if (!transactionReference) {
        return <UnAuthorized />;
    }

    return (
        <div>
            <div className="d-flex justify-content-center">
                <h5 style={{color: "#0b456e", textAlign: "center"}}>
                    Payment of <span style={{fontSize: "1.5rem", fontWeight: "bold"}}>{`$${amounts[selectedWalletCode].amount_in_fiat.toFixed(2)} (${amounts[selectedWalletCode].amount_in_crypto} ${selectedWalletCode})`}</span> to {legalName} successfully processed.
                </h5>
            </div>
            {/*<div className="d-flex justify-content-center mt-1">*/}
            {/*    <h5 style={{color: "#0b456e"}}>{legalName} successfully processed.</h5>*/}
            {/*</div>*/}
            <div className="d-flex justify-content-center mt-3">
                <img src={correct} alt="merchant" style={{width: "6rem"}}/>
            </div>
            <div className="d-flex justify-content-center mt-4">
                <h5 style={{color: "#0b456e", textAlign: "center"}}>Transaction id:</h5>
            </div>
            <div className="d-flex justify-content-center">
                <h5 style={{color: "#0b456e", textAlign: "center"}}>{transactionReference}</h5>
            </div>
            <div className="d-flex justify-content-center mt-4">
                <button className="btn btn-lg" style={{backgroundColor: "#0b456e", color: "white"}} onClick={backToCheckout}>
                    Back to checkout <FontAwesomeIcon icon={faSpinner} spin />
                </button>
            </div>
        </div>
    );
}